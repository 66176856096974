// 左侧导航栏文件
export default {
	logo: '伟趣',
	navBars: {
		active: '0',
		list: [{
				name: '首页',
				subActive: '0',
				submenu: [
					{
						icon: "el-icon-picture",
						name: "配置",
						pathname: 'config_list'
					},
					// {
					// 	icon: "el-icon-s-platform",
					// 	name: "后台首页",
					// 	pathname: 'index'
					// },
					{
						icon: "el-icon-picture",
						name: "相册管理",
						pathname: 'image'
					},
					{
						icon: "el-icon-picture",
						name: "文章",
						pathname: 'article_list'
					},
				]
			},
			{
				name: '订单',
				subActive: '0',
				submenu: [
					{
						icon: "el-icon-s-order",
						name: "订单列表",
						pathname: 'order_order_list'
					},
					{
						icon: "el-icon-s-order",
						name: "评价列表",
						pathname: 'order_comment_list'
					},
				]
			},
			{
				name: '拼团',
				subActive: '0',
				submenu: [
					{
						icon: "el-icon-menu",
						name: "拼团清单列表",
						pathname: 'pt_pt_list'
					},
					{
						icon: "el-icon-menu",
						name: "随心配列表",
						pathname: 'shop_sxp_list'
					},
					{
						icon: "el-icon-menu",
						name: "商品列表",
						pathname: 'shop_goods_list'
					},
				]
			},
			
			// {
			// 	name: '会员',
			// 	subActive: '0',
			// 	submenu: [{
			// 			icon: "el-icon-s-order",
			// 			name: "会员信息",
			// 			pathname: 'member_userList_list'
			// 		},
			// 	]
			// },
			// {
			// 	name: '营销管理',
			// 	subActive: '0',
			// 	submenu: [
			// 		{
			// 			icon: "el-icon-s-order",
			// 			name: "广告列表",
			// 			pathname: 'ad_ad_list'
			// 		}
			// 	]
			// }
		]
	}
}
