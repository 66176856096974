import Vue from "vue"
import Router from "vue-router"
import routes from './common/config/router.js'
Vue.use(Router)
let router=new Router({routes})
// 全局前置守卫 拦截后next方向 登录
router.beforeEach((to,from,next) => {
	let token=window.sessionStorage.getItem('token')
	// 判断是否登录
	if(token){
		// 1 防止重复登录
		// if(to.path==='/login'){
		// 	Vue.prototype.$message.error('请不要重复登录')
		// 	return next({name:from.name?from.name:'index'})
		// }
        next()
		// 权限控制
		// if(to.name !== 'error_404'){
		// 	let rules=window.sessionStorage.getItem('rules')
		// 	rules=rules?JSON.parse(rules):[]
		// 	let index=rules.findIndex(v=>{
		// 		return v.rule_id>0&&v.desc===to.name
		// 	})
		// 	if(index===-1){
		// 		Vue.prototype.$message.error('你没有权限')
		// 		return next({name:from.name?from.name:'error_404'})
		// 	}
		// }
	}else{
		if(to.path==='/login'){
			return next()
		}
		// 无限跳转
		Vue.prototype.$message.error('请先登录')
		next({path:'/login'})
	}
})
export default router
	
