// 1 自动生成路由 递归 2自动生成path和name，如果填写则不自动生成 
// index结尾去掉index
function createRoute(arr){
	for(let i in arr){
		if(!arr[i].component)return
		// 去除index结尾
		let val=getValue(arr[i].component)
		// 生成name
		arr[i].name=arr[i].name||val.replace(/\//g,'_')
		// 生成path
		arr[i].path=arr[i].path||`/${val}`
		let componentFun=import(`../../views/${arr[i].component}.vue`)
		arr[i].component=()=>componentFun
		if(arr[i].children&&arr[i].children.length>0){
			createRoute(arr[i].children)
		}
	}
}

function getValue(str){
	// 'login/index'  返回5
	let index=str.lastIndexOf('/')
	// 5+1位置开始截取
	let val=str.substring(index+1,str.length)
	if(val === 'index'){
		return str.substring(index,-1)
	}
	return str
}

const routes=[
		{
			path:'/',
			name:'layout',
			redirect:{name:'index'},
			component:'layout',
			children:[
				{
					meta:{title:'404'},
					component:'error/404',
				},
				{
					meta:{title:'后台首页'},
					component:'index/index',
				},
				{
					meta:{title:'会员信息'},
					component:'member/userList/list',
				},
				{
					meta:{title:'文章列表'},
					component:'article/list',
				},
				{
					meta:{title:'配置列表'},
					component:'config/list',
				},
				{
					meta:{title:'广告列表'},
					component:'ad/ad/list',
				},
				{
					meta:{title:'相册管理'},
					component:'image/index',
				},
				{
					meta:{title:'商品列表'},
					component:'shop/goods/list',
				},
				{
					meta:{title:'随心配列表'},
					component:'shop/sxp/list',
				},
				{
					meta:{title:'发布商品'},
					component:'shop/goods/create/base',
				},
				{
					meta:{title:'订单列表'},
					component:'order/order/list',
				},
				{
					meta:{title:'评价订单列表'},
					component:'order/comment/list',
				},
				{
					meta:{title:'清单列表'},
					component:'pt/pt/list',
				},
			]
		},
		{
			meta:{title:'登录页'},
			component:'login/index',
		},
		// 默认均跳转index
		{
			path:'*',
			redirect:{name:'index'}
		}
    ]

// 获取路由信息方法
const getRoutes=function(){
	createRoute(routes)
	return routes
}

export default getRoutes()