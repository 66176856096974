import ElementUI from 'element-ui'//element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css
Vue.use(ElementUI)//使用elementUI,此项位置不可变
// 引入axios 需要单独添加request文件
import './plugins/axios'
import { Message } from 'element-ui'
let loading = null
let requestCount = 0
// 显示loading
function showLoading() {
  if (requestCount === 0) {
    loading = Message({
      message: '加载中...',
      duration: 0
    })
  }
  requestCount++
}
// 隐藏loading
function hideLoading() {
  if (requestCount > 0) {
    requestCount--
    if (loading && requestCount === 0) {
      loading.close()
    }
  }
}

axios.defaults.baseURL = '/api/cater'
// 前置守卫 添加请求拦截器 传header头 
axios.interceptors.request.use((config) => {
  // 在发送请求之前做些什么 全局添加header头
  if(config.token == 'arx'){
	  config.headers['token'] = 'arx'
  }
  if (config.token === true) {
    let token = window.sessionStorage.getItem('token')
    config.headers['token'] = token
  }
  // console.log(config)
  if (config.loading === true) {
    showLoading()
  }
  return config;
}, (err) => {
  hideLoading()
  // 对请求错误做些什么
  return Promise.reject(err);
})

// 添加响应拦截器
axios.interceptors.response.use((response) => {
  hideLoading()
  return response; // 对响应数据做点什么
}, (err) => {
  // 状态码不是200进入这里
  hideLoading()
  // 对响应错误做点什么
  if (err.response && err.response.data && err.response.data.detail) {
    Message.error(err.response.data.detail);
  } else {
    Message.error('服务器未响应');
  }
  return Promise.reject(err);
})

import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
// router
import router from './router.js'
// vuex
import store from './store/index.js'
// 引入全局配置文件 配置了侧边栏的数组
import $conf from './common/config/config.js'
Vue.prototype.$conf = $conf

Vue.config.productionTip = false
// 导出
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')