<template>
	<li @click="$emit('change',index)" class="list-group-item list-group-item-action d-flex justify-content-between" style="cursor: pointer;" :class="{'sum-active':active}" >
		  <span>{{item.PICNAME}}</span>
		  <span class="btn btn-light btn-sm ou-ml-auto" v-if="!showOptions">
		    >
		  </span>
		  <el-dropdown v-else>
			<span class="btn btn-light btn-sm">
			  <i class="el-icon-arrow-down el-icon--right"></i>
			</span>
			<el-dropdown-menu slot="dropdown">
			<el-dropdown-item @click.stop.native="$emit('edit',{item,index})">修改
			</el-dropdown-item>
			<el-dropdown-item @click.stop.native="$emit('del',index)">删除	 
			 </el-dropdown-item>
			</el-dropdown-menu>
		  </el-dropdown>
	</li>
</template>

<script>
	export default{
		props:{
			item:Object,
			index:Number,
			active:{
				type:Boolean,
				default:false
			},
			showOptions:{
				type:Boolean,
				default:true
			}
		},
	}
</script>

<style>
	.sum-active{
		color: #67c23A!important;
		background-color: #F0F9EB!important;
		border-color: #C2E7B0!important;
	}
</style>
