import '../../plugins/axios';
import {Message} from 'element-ui'
export default{
	inject: ['layout'],
	state:{
		// 基础设置
		ITEMNAME:"",
		ITEMCODE:"",
		SMALLID:"",
		UNIT:"",
		IMG:"",
		CONTENT:"",
		WCONTENT:"welcome",
		// arr
		arr: [
			   {key:'ITEMNAME',value:''},{key:'ITEMCODE',value:''},
			   {key:'SMALLID',value:''},{key:'IMG',value:''},
			   {key:'UNIT',value:''},
			   {key:'CONTENT',value:''},{key:'WCONTENT',value:"welcome"}
		],
		// 0修改状态 1修改完毕 2修改失败
		status:0
	},
	// 处理状态 类似methods
	mutations:{
		// 修改state
	   vModelState(state,{key,value}){
		   state[key]=value
	   },
	   // 初始化商品
	   initGood(state, good){
		   state.arr.forEach(v=>{
			   if(good[v.key]){
					state[v.key]=good[v.key]
			   }
		   })
	   },
	   // 糟糕的初始化方法
	   reset(state){
		//    console.log('reset')
		   state.arr.forEach(v=>{
			   state[v.key]=v.value
		   })
	   }
	},
	// 状态显示
	getters:{
	},
	// 异步操作
	actions:{
		// 批量删除
		submitGoods({
			state,
			getters,
			commit 
		}) {
			let url = `/sku_back/?ITEMCODE=${state.ITEMCODE}&type=1`
			let method = 'PUT'
			let options = {
				method: method,
				url: url,
				data: {
					ITEMNAME:state.ITEMNAME,
					SMALLID:state.SMALLID,
					UNIT:state.UNIT,
					IMG:state.IMG,
					CONTENT:state.CONTENT,
					WCONTENT:state.WCONTENT,
				},
				token: true
			}
			axios(options).then(res => {
				setTimeout(()=>{
					state.status = 1
					Message({
						message: '商品修改成功',
						type: 'success'
					})
					commit('reset')
				},5000)
			}).catch(err => {
				state.status = 2
			})
		}
	}
}