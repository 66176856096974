<template>
  <div id="app">
    <router-view></router-view>
    <!-- 图片选择 -->
    <imageDialog ref="imageDialog" :max='maxChooseImages'></imageDialog>
  </div>
</template>
<script>
import imageDialog from '@/components/image/image-dialog.vue'
export default {
  name: 'app',
  // 依赖注入 this.app可以使用
  provide() {
    return {
      app: this
    }
  },
  data() {
    return {
      maxChooseImages: 9
    }
  },
  created() {
    // console.log('初始化用户信息')
    this.$store.commit('initUser');
    // 初始化后台菜单
    this.$store.commit('initNavBar');
  },
  methods: {
    // 选择图片
    chooseImage(callback, max = 9) {
      this.maxChooseImages = max
      this.$refs.imageDialog.chooseImage(callback)
    },
  },
  components: {
    imageDialog
  }
}
</script>

<style>
@import url("./assets/css/common.css");
</style>
