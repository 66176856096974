<template>
	<!-- 让弹出层代码不多次渲染 -->
	<el-dialog title="图片管理" :visible.sync="imageModel" width="80%" top="5vh">
		<el-container style="position: relative;height: 70vh;margin:-30px -20px;">
			<el-header class="d-flex align-items-center justify-content-between ou-border-bottom">
				<div class="d-flex">
					<el-select v-model="searchForm.order" @change="getImageList" placeholder="请修改图片排序方式" size="mini"
						class="ou-mr-2" style="width:300px;">
						<el-option label="升序" value="ID"></el-option>
						<el-option label="降序" value="-ID"></el-option>
					</el-select>
					<el-input size="mini" class="ou-mr-2" placeholder="输入图片名称" v-model="searchForm.keyword"></el-input>
					<el-button type="success" size="mini" @click='getImageList'>搜索</el-button>
				</div>
			</el-header>
			<el-container>
				<!-- 侧边相册列表 -->
				<el-aside class="ou-position-a bg-white ou-border-right" style="top:60px;left:0;bottom:60px;"
					width="200px">
					<div>
                        <el-input size="mini" class="ou-mr-2" placeholder="输入相册名称" v-model="album_keyword" @change="getAlbumsList"></el-input>
                    </div>
					<ul class="list-group list-group-flush">
						<albumItem @change='albumChange' :showOptions='false' v-for="(item, index) in albums"
							:key='index' :item='item' :index='index' :active="albumIndex == index"></albumItem>
					</ul>
				</el-aside>
				<!-- 图片列表 -->
				<el-container>
					<el-main class="ou-position-a" style="top:60px;left:200px;right:0;bottom:60px;" v-loading="loading">
						<el-row :gutter="10">
							<!-- 循环开始 -->
							<el-col :span="24" :lg="4" :md="6" :sm="8" v-for="(item, index) in imageList" :key='index'>
								<el-card class="box-card ou-mb-3 " :body-style="{ padding: '0' }" shadow='hover'
									style="cursor:pointer;">
									<!-- 在card里面设置无效 -->
									<div class="border" :class="{ 'border-danger': item.isSelected }">
										<div class="ou-position-r" @click="choose(item)">
											<!-- 尽量不要使用组件，使用样式 -->
											<span v-if="item.isSelected"
												class="badge ou-bg-mid-red ou-position-a ou-t-0 ou-r-0">{{ item.selectOrder }}</span>
											<img class="w-100 ou-h-2 " :src="item.IMG_URL" />
											<div class="ou-pl-1 ou-bg-mask-color w-100 text-white ou-position-a"
												style="bottom:0">{{ item.TITLE }}</div>
										</div>
										<div class="ou-p-1 w-100 text-center">
											<el-button-group>
												<el-button class="ou-p-2" icon="el-icon-edit" size="mini"
													@click="editImage(index, item)"></el-button>
												<el-button class="ou-p-2" icon="el-icon-delete" size="mini"
													@click="delImage(item.ID)"></el-button>
											</el-button-group>
										</div>
									</div>

								</el-card>
							</el-col>
							<!-- 循环结束 -->
						</el-row>
					</el-main>
				</el-container>
			</el-container>
			<!-- 分页 -->
			<el-footer class='d-flex align-items-center border-top px-0'>
				<!-- 底部 -->
				<div class="h-100 ou-flex-row-center ou-border-right" style="width: 200px;flex-shrink: 0;">
					<el-button-group>
						<el-button type="mini" :disabled="albumPage.current === 1" @click="changeAlbumPage('pre')">上一页
						</el-button>
						<el-button type="mini" :disabled="albumPage.current === albumsTotalPage"
							@click="changeAlbumPage('next')">下一页</el-button>
					</el-button-group>
				</div>
				<div class="ou-flex-1 ou-pl-2">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="page.current" :page-sizes="page.sizes" :page-size="page.size"
						layout="total, sizes, prev, pager, next, jumper" :total="page.total">
					</el-pagination>
				</div>
			</el-footer>
		</el-container>

		<div slot="footer" class="dialog-footer">
			<el-button @click="hide">取 消</el-button>
			<el-button type="primary" @click="confirm">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import albumItem from '@/components/image/album-item.vue'
export default {
	props: {
		// 选择图片数量
		max: {
			type: Number,
			default: 9
		}
	},
	data() {
		return {
			loading: false,
			imageModel: false,
			callback: false,
			searchForm: {
				order: "",
				keyword: ""
			},
			// 侧边相册
			albums: [],
			albumIndex: 0,
			album_keyword:'', //相册搜索字段
			imageList: [],
			// 选中数组
			chooseList: [],
			// 分页
			albumsPageSize: 8,
			albumPage: {
				current: 1,
				total: 0
			},
			// 分页
			page: {
				current: 1,
				size: 20,
				sizes: [10, 20, 50, 100],
				total: 0
			},
		}
	},
	created() {
		this.getAlbumsList()
	},
	computed: {
		// 选中相册的id
		imageClassId() {
			let item = this.albums[this.albumIndex]
			if (item) {
				return item.ID
			}
			return 0
		},
		// get请求写法 计算属性管理自动修改url 获取相册图片列表url
		imageUrl() {
			let other = ''
			if (this.searchForm.keyword) {
			    other = `&TITLE=${this.searchForm.keyword}`
			}
			let page = this.page
			let url = {
				method: 'GET',
				url: `/image/?page=${page.current}&page_size=${page.size}&CATEGORY=${this.imageClassId}
                    &ordering=${this.searchForm.order}${other}`,
				token: 'arx'
			}
			return url
		},
		albumsTotalPage() {
			return Math.ceil(this.albumPage.total / this.albumsPageSize)
		}
	},
	methods: {
		//打开弹出层
		chooseImage(callback) {
			// 全选选中
			this.unChoose()
			this.callback = callback
			this.imageModel = true
		},
		// 关闭弹出层
		hide() {
			this.callback = false
			this.imageModel = false
		},
		// 确认
		confirm() {
			// 返回url
			if (typeof this.callback === 'function') {
				this.callback(this.chooseList)
			}
			this.hide()
		},
		// 分页相关 修改每页条数
		handleSizeChange(val) {
			this.page.current = 1
			this.page.size = val
			this.getImageList()
		},
		// 修改页码
		handleCurrentChange(val) {
			this.page.current = val
			this.getImageList()
		},
		// 左侧切换
		albumChange(index) {
			this.albumIndex = index
			this.getAlbumsList()
		},
		// 相册分页
		changeAlbumPage(val) {
			if (val === 'pre') {
				this.albumPage.current--
			} else {
				this.albumPage.current++
			}
			this.getAlbumsList()
		},
		// 初始化相册 初始化图片列表
		getAlbumsList() {
			this.showLoading()
			this.initPage()
			// 页码
			let albumPage = this.albumPage
			// 获取相册列表
			let options = {
				method: 'GET',
				url: `/image-cate/?page=${albumPage.current}&page_size=${this.albumsPageSize}&PICNAME=${this.album_keyword}`,
				token: 'arx'
			}
			this.axios(options).then(res => {
				let data = res.data
				this.albums = data.results
				albumPage.total = data.count
				console.log(this.albums);
				this.getImageList()
			}).catch(err => {
				this.hideLoading()
			})
		},
		// 异步操作 获取对应图片列表
		getImageList() {
			this.showLoading()
			this.axios(this.imageUrl).then(res => {
				let data = res.data
				this.page.total = data.count
				this.imageList = data.results.map(v => {
					return {
						ID: v.ID,
						IMG_URL: v.IMG_URL,
						TITLE: v.TITLE,
						isSelected: false, // 是否选中
						selectOrder: 0 // 选中顺序
					}
				})
				console.log(this.imageList)
				this.hideLoading()
			}).catch(err => {
				this.showLoading()
			})
		},
		// 初始化图片分页
		initPage() {
			this.searchForm = {
				order: "-ID",
				keyword: ""
			}
			this.page = {
				current: 1,
				size: 20,
				sizes: [10, 20, 50, 100],
				total: 0
			}
		},
		// 编辑图片
		// 编辑图片 js模态框
		editImage(index, item) {
			this.$prompt('请输入新名称', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputValue: item.TITLE,
				inputValidator(val) {
					if (val === '') {
						return '图片名称不能为空'
					}
				}
			}).then(({
				value
			}) => {
				this.imageList[index].TITLE = value
				let options = {
					method: 'PATCH',
					url: `/image/${this.imageList[index].ID}/`,
					data: { TITLE: value },
					token: true
				}
				this.axios(options).then(res => {
					this.$message({
						message: '修改成功',
						type: 'success'
					});
				})

			})
		},
		// 删除图片
		delImage(obj) {
			this.$confirm('是否删除该图片', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				// 删除单个
				let options = {
					method: 'delete',
					url: `/image/${obj}`,
					token: true
				}
				this.axios(options).then(res => {
					this.getImageList()
					this.$message({
						message: '删除成功',
						type: 'success'
					});
				})
			})
		},
		// 选中图片
		choose(item) {
			// 选中
			if (!item.isSelected) {
				if (this.chooseList.length >= this.max) {
					return this.$message({
						message: `最多选择${this.max}张图片`,
						type: 'warning'
					})
				}
				// 加入选中
				this.chooseList.push({
					ID: item.ID,
					image: item.IMG_URL
				})
				// 显示序号
				item.selectOrder = this.chooseList.length
				item.isSelected = true
				return
			} else {
				// 取消选中 找到索引后删除
				let index = this.chooseList.findIndex(v => v.id === item.id)
				if (index === -1) return;
				let length = this.chooseList.length
				// 重新排序
				if (index + 1 < length) {
					// 从索引后面开始循环
					for (let j = index; j < length; j++) {
						// 后面的全部减去1
						let back = this.imageList.findIndex(v => v.id === this.chooseList[j].id)
						if (back > -1) {
							this.imageList[back].selectOrder--
						}
					}
				}
				// 重新计算序号
				this.chooseList.splice(index, 1)
				item.isSelected = false
				item.selectOrder = 0
			}
		},
		// 取消选中
		unChoose() {
			this.imageList.forEach(v => {
				let i = this.chooseList.findIndex(item => item.id === v.id)
				if (i > -1) {
					v.isSelected = false
					v.selectOrder = 0
				}
			})
			this.chooseList = []
		},
		// loading
		showLoading() {
			this.loading = true
		},
		hideLoading() {
			this.loading = false
		}
	},
	components: {
		albumItem
	}
}
</script>

<style>
</style>
