import $conf from '../../common/config/config.js'

export default{
	state:{
		navBars:{
			active:'0',
			list: [{
				name: '首页',
				subActive: '0',
				submenu: [{
						icon: "el-icon-s-platform",
						name: "后台首页",
						pathname: 'index'
					}
				]
			}]
		}
	},
	// 处理状态 类似methods
	mutations:{
		// 初始化菜单
		initNavBar(state){
			let navBars = window.sessionStorage.getItem('navBars')
			navBars=navBars?JSON.parse(navBars):$conf.navBars
			state.navBars=navBars
		},
	
		// 创建菜单
		createNavBar(state,menus){
			let list = menus.map(item=>{
				let submenu = item.child.map(v=>{
					return{
							icon: v.icon,
							name: v.name,
							pathname:v.desc
						}
				})
				return {
					name: item.name,
					subActive: '0',
					submenu:submenu
				}
			})
			state.navBars.list=list
			window.sessionStorage.setItem('navBars',JSON.stringify(state.navBars))
		}
	},
	// 状态显示
	getters:{
		// 首页
		adminIndex(state){
			if(state.navBars.list.length === 0){
				return 'error_404'
			}
			let item = state.navBars.list[0].submenu[0]
			if(item){
				return item.pathname
			}
		}
	},
	// 异步操作
	actions:{
		
	}
}